import React from 'react'
import { DemoIcon, DownloadBigIcon } from '../../lib/components/basics/icons'

// HERO PROPERTIES (Used in HeroModule component)
export const heroProps = {
    title: (
        <>
            All your Kafkas. <br />
            One experience.
        </>
    ),
    primaryCtaLink: '/request-quote',
    primaryCtaLabel: 'Get quote',
}

export const packageIncludesProps = {
    isLight: true,
    height: 'fit-content' as const,
    description: (
        <small>
            <b>* Add-on Packages:</b> Enterprise support for connectors | Topic backup and restore
        </small>
    ),
}

//PREFOOTER
export const preFooterTitle = 'One product. Unlimited productivity.'

export const ReviewsPrefooter = [
    {
        review: 'Lenses is amazing at providing insights into the Kafka topics',
        author: 'Alexandru-Florian A',
    },
    {
        review: 'The Lenses product is amazing and I am positive it has drastically increased our development times using Kafka.',
        author: 'Matthew G.',
    },
    {
        review: 'Beautiful, responsive UI for kafka overview and with lenses SQL for kafka topics browsing',
        author: 'Ivan V.',
    },
    {
        review: "Lenses provides a quick inside view of all your data flow. There's not much to dislike.",
        author: 'Kyriakos P.',
    },
    {
        review: 'Lenses is an intuitive tool that provides great observability and management features for our streaming',
        author: 'Anonymous',
    },
    {
        review: 'A very respectable add-on for enterprise-grade Kafka clusters!',
        author: 'Donny F.',
    },
    { review: 'Invaluable tool for anyone using Kafka', author: 'Michael G.' },
    {
        review: `Very complete tool to browse and understand what's happening inside your clusters`,
        author: 'George F.',
    },
]

export const cardsDataPrefooter = [
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: 'primary' as const,
        description: [
            'Guided tour of Lenses',
            'Trial key to install on your clusters',
            'Great for production and large-scale Kafka deployments',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
    {
        title: 'Free Lenses',
        leadingText: 'Run the docker command to get Lenses Community Edition:',
        type: 'secondary',
        description: [
            'Apache Kafka Docker + Lenses 6.0',
            'Pre-loaded synthetic data',
            'Up to 2 users + environments',
        ],
        buttonText: 'Download',
        href: '/community-edition/',
        icon: <DownloadBigIcon key='downloadBigIcon' />,
    },
]
