import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Layout from '../../components/Layout'
import PricingPage from '../../hanzo/pages/pricing'

const PricingPageWrapper: React.FC = () => {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: 'product_pricing_seen',
            })
        }
    }, [])

    const data = useStaticQuery(graphql`
        query PricingSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Product_Pricing" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout newsletter={false} linkColor={'dark'} isTransparent={true}>
            <HeaderSEO pageSEO={pageSEO} />
            <PricingPage />
        </Layout>
    )
}

export default PricingPageWrapper
